import Manisha from "../../assets/images/manisha1.jpg"
import Harsh from "../../assets/images/harsh.jpg"



const Data = [
    {
    "img":Manisha,
    "name":"Manisha Sahoo",
    "linkedin":"https://www.linkedin.com/in/manisha-sahoo-33186022b",
    "mail":"manishasahoo995@gmail.com",
    "facebook":"https://www.facebook.com/profile.php?id=100075561559224&mibextid=ZbWKwL",
    "position":"Governer"
},
{
    "img":Harsh,
    "name":"Harshvardhan ",
    "linkedin":"https://www.linkedin.com/in/harshvardhan-4a76",
    "mail":"neerajsharmasony@gmail.com",
    "position":"Governer"

}
]

export default Data